/* eslint-disable no-async-promise-executor */
import axios from "axios";
export default {
    getUsers() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get("/admin/all-users");
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    getUserTxn({ userId }) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get(
                    `admin/transaction/user?userId=${userId}`
                );
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    getSingleUserDetails({ userId }) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get(`admin/user?userId=${userId}`);
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    reset(id) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.put(`/admin/reset-user?userId=${id}`);
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
};