<template>
  <div class="mb-4">
    <div class="card-deck">
      <InfoCard
        :value="totalTransactions"
        :isNaira="false"
        title="Total Txn"
        icon="card"
      />
      <InfoCard
        :value="totalCompletedTransactions"
        :isNaira="false"
        title="Total Completed Txn"
        icon="card"
      />

      <InfoCard
        :value="totalGiftcardTransactions"
        :isNaira="false"
        title="Total Giftcard Txn"
        icon="card"
      />
    </div>
    <br />
    <div class="card-deck">
      <InfoCard
        :value="sumCompletedTransactions"
        :isNaira="true"
        title="Sum Completed Txn"
        icon="card"
      />
      <InfoCard
        :value="sumCancelledTransactions"
        :isNaira="true"
        title="Sum Cancelled Txn"
        icon="card"
      />
      <InfoCard
        :value="sumGiftcardTransactions"
        :isNaira="true"
        title="Sum Giftcard Txn"
        icon="gift"
      />
    </div>
  </div>
</template>

<script>
import InfoGraphService from "@/services/infograph.service.js";
import InfoCard from "../../components/dashboard/InfoCard.vue";
export default {
  name: "TxnStats",
  components: {
    InfoCard,
  },
  data() {
    return {
      totalTransactions: null,
      totalCompletedTransactions: null,
      totalGiftcardTransactions: null,
      sumCompletedTransactions: null,
      sumCancelledTransactions: null,
      sumGiftcardTransactions: null,
    };
  },
  methods: {
    async walletStats() {
      try {
        const response = await InfoGraphService.txnStats();
        this.totalTransactions = response.data.totalTransactions;
        this.totalCompletedTransactions =
          response.data.totalCompletedTransactions;
        this.totalGiftcardTransactions =
          response.data.totalGiftcardTransactions;
        this.sumCompletedTransactions = response.data.sumCompletedTransactions;
        this.sumCancelledTransactions = response.data.sumCancelledTransactions;
        this.sumGiftcardTransactions = response.data.sumGiftcardTransactions;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    this.walletStats();
  },
};
</script>

<style scoped></style>
