/* eslint-disable no-async-promise-executor */
import axios from "axios";

export default {
    withdrawalStats() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get("/admin/withdraw-stats");
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    txnStats() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get("/admin/transactions-stats");
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    walletStats() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get("/admin/wallet-stats");
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    giftCardStats() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get("/admin/giftcard-stats");
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
};