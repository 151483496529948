<template>
  <div class="card border-0 shadow">
    <div class="card-body">
      <div>
        <p class="h3">
          <span v-if="isNaira">&#8358;</span>{{ value | formartNumber }}
        </p>
      </div>
      <div class=" d-flex justify-content-between align-items-center">
        <p class="lead text-muted mb-0  ">{{ title }}</p>
        <div class="p-2 colorOneBg mainThree rounded-circle">
          <ion-icon size="large" :name="icon"></ion-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calls from "@/Calls";
export default {
  name: "InfoCard",
  props: ["value", "isNaira", "title", "icon"],
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
};
</script>
