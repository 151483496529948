<template>
  <div class="mb-4">
    <div class="card-deck">
      <InfoCard
        :value="totalWithdrawRequests"
        :isNaira="false"
        title="total Withdraw Requests"
        icon="card"
      />
      <InfoCard
        :value="totalApprovedWithdrawRequests"
        :isNaira="false"
        title="Total Approved Requests"
        icon="card"
      />

      <InfoCard
        :value="totalCancelledWithdrawRequests"
        :isNaira="false"
        title="Total Cancelled Requests"
        icon="card"
      />
    </div>
    <br />
    <div class="card-deck">
      <InfoCard
        :value="sumWithdrawRequests"
        :isNaira="true"
        title="Sum Withdraw Requests"
        icon="card"
      />
      <InfoCard
        :value="sumApprovedWithdrawRequests"
        :isNaira="true"
        title="Sum Approved Requests"
        icon="card"
      />
      <InfoCard
        :value="sumCancelledWithdrawRequest"
        :isNaira="true"
        title="Sum Cancelled Request"
        icon="gift"
      />
    </div>
  </div>
</template>

<script>
import InfoGraphService from "@/services/infograph.service.js";
import InfoCard from "../../components/dashboard/InfoCard.vue";
export default {
  name: "TxnStats",
  components: {
    InfoCard,
  },
  data() {
    return {
      totalWithdrawRequests: null,
      totalApprovedWithdrawRequests: null,
      totalCancelledWithdrawRequests: null,
      sumWithdrawRequests: null,
      sumApprovedWithdrawRequests: null,
      sumCancelledWithdrawRequest: null,
    };
  },
  methods: {
    async walletStats() {
      try {
        const response = await InfoGraphService.withdrawalStats();
        this.totalWithdrawRequests = response.data.totalWithdrawRequests;
        this.totalApprovedWithdrawRequests =
          response.data.totalApprovedWithdrawRequests;
        this.totalCancelledWithdrawRequests =
          response.data.totalCancelledWithdrawRequests;
        this.sumWithdrawRequests = response.data.sumWithdrawRequests;
        this.sumApprovedWithdrawRequests =
          response.data.sumApprovedWithdrawRequests;
        this.sumCancelledWithdrawRequest =
          response.data.sumCancelledWithdrawRequest;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    this.walletStats();
  },
};
</script>

<style scoped></style>
