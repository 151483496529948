<template>
  <div>
    <p class="h3 ">Overview</p>
    <OverviewStats />
    <p class="h3">GiftCard Stats</p>
    <GiftCardStats />
    <p class="h3">Txn Statistics</p>
    <TxnStats />
    <p class="h3">Withdrawal Statistics</p>
    <WithdrawalTxnStats />
  </div>
</template>

<script>
import OverviewStats from "./dashboard/OverviewStats.vue";
import TxnStats from "./dashboard/TxnStats.vue";
import GiftCardStats from "./dashboard/GiftCardStats";
import WithdrawalTxnStats from "./dashboard/WithdrawalTxnStats";
export default {
  name: "Dashboard",
  components: {
    OverviewStats,
    TxnStats,
    GiftCardStats,
    WithdrawalTxnStats,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
