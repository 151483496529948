<template>
  <div class="mb-4">
    <div class="card-deck">
      <InfoCard
        :value="totalCompletedGiftcards"
        :isNaira="false"
        title="Total Completed Giftcards"
        icon="gift"
      />
      <InfoCard
        :value="totalCancelledGiftcards"
        :isNaira="false"
        title="Total Cancelled Giftcards"
        icon="gift"
      />

      <InfoCard
        :value="totalPendingGiftcards"
        :isNaira="false"
        title="Total Pending Giftcards"
        icon="gift"
      />
    </div>
    <br />
    <div class="card-deck">
      <InfoCard
        :value="sumCompletedGiftcards"
        :isNaira="true"
        title="Sum of Completed Giftcards"
        icon="card"
      />
      <InfoCard
        :value="sumPendingGiftcards"
        :isNaira="true"
        title="Sum of Pending Giftcards"
        icon="gift"
      />
      <InfoCard
        :value="sumCancelledGiftcards"
        :isNaira="true"
        title="Sum of Cancelled Giftcards"
        icon="gift"
      />
    </div>
  </div>
</template>

<script>
import InfoGraphService from "@/services/infograph.service.js";
import InfoCard from "../../components/dashboard/InfoCard.vue";
export default {
  name: "TxnStats",
  components: {
    InfoCard,
  },
  data() {
    return {
      totalCompletedGiftcards: null,
      totalCancelledGiftcards: null,
      totalPendingGiftcards: null,
      sumCompletedGiftcards: null,
      sumPendingGiftcards: null,
      sumCancelledGiftcards: null,
    };
  },
  methods: {
    async giftCardStats() {
      try {
        const response = await InfoGraphService.giftCardStats();
        this.totalCompletedGiftcards = response.data.totalCompletedGiftcards;
        this.totalCancelledGiftcards = response.data.totalCancelledGiftcards;
        this.totalPendingGiftcards = response.data.totalPendingGiftcards;
        this.sumCompletedGiftcards = response.data.sumCompletedGiftcards;
        this.sumPendingGiftcards = response.data.sumPendingGiftcards;
        this.sumCancelledGiftcards = response.data.sumCancelledGiftcards;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    this.giftCardStats();
  },
};
</script>

<style scoped></style>
