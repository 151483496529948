<template>
  <div class="mb-4">
    <div class="card-deck">
      <InfoCard
        :value="totalNo"
        :isNaira="false"
        title="Total No. of users"
        icon="people"
      />

      <InfoCard
        :value="totalWalletBalance"
        :isNaira="true"
        title="System Wallet"
        icon="card"
      />

      <InfoCard
        :value="totalGiftcardTraded"
        :isNaira="false"
        title="Total GiftCard Traded"
        icon="gift"
      />
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user.service.js";
import InfoGraphService from "@/services/infograph.service.js";
import InfoCard from "@/components/dashboard/InfoCard.vue";
import Calls from "@/Calls";
export default {
  name: "Stats",
  components: { InfoCard },
  data() {
    return {
      totalNo: null,
      totalWalletBalance: null,
      totalGiftcardTraded: null,
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await UserService.getUsers();
        this.totalNo = response.data.count;
      } catch (error) {
        console.log(error);
      }
    },
    async walletStats() {
      try {
        const response = await InfoGraphService.walletStats();
        this.totalWalletBalance = response.data.currentTotalWalletBalance;
      } catch (error) {
        console.log(error);
      }
    },

    async giftCardStats() {
      try {
        const response = await InfoGraphService.giftCardStats();
        this.totalGiftcardTraded = response.data.totalGiftcardTraded;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    this.fetchUsers();
    this.walletStats();
    this.giftCardStats();
  },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
};
</script>

<style scoped></style>
